<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Rola</h3>
                    <h2 class="" v-if="role.title">{{role.title}}</h2>
                    <h2 class="" v-else>Nová rola</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Nastavenia</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Rola"
                                                        input-classes="form-control-alternative"
                                                        v-model="role.title"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Slug"
                                                        input-classes="form-control-alternative"
                                                        v-model="role.slug"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                    <div class="col-lg-6">
                                        <a-input alternative=""
                                                    label="Hlavná stránka"
                                                    input-classes="form-control-alternative"
                                                    v-model="role.homepage"
                                        />
                                    </div>
                                    <div class="col-lg-6">

                                    </div>
                                </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Právomoci</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <template>
                                            <div>
                                                 <a-checkbox-group :span="12" v-model="role.permissions"  :options="permissions"/>
                                            </div>
                                        </template>

                                    </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                        <template>
                            <form @submit.prevent="handlePaths">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <template>
                                            <div>
                                                <a-checkbox-group :span="12" v-model="role.paths" :options="paths"/>
                                            </div>

                                        </template>

                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <h2>Typy obsahu</h2>
                                        <template>
                                               <div>
                                                <a-checkbox-group :span="12" v-model="role.ctypes" :options="contenttypes"/>
                                            </div>
                                        </template>

                                    </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'

    Vue.use(FormItem);


    export default {
        name: 'role',
        props: ['id'],

        data() {
            return {
                head: authHeader(),
                send: API_URL,
                permissions: [],
                colorsto: JSON.parse(localStorage.colors),
                paths: [],
                perm:[],
                contenttypes:[],
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                roleId: {
                    uid: this.id
                },
                role: {
                    title: '',
                    slug: '',
                },
            }
        },
        methods: {
            handleSubmit() {
                let $role_data = this.role;
                dataService.axiosPost(this.$options.name, $role_data).then(results => {

                    if (results.data.message) {
                        this.$router.push("/" + this.$options.name + "/" + results.data.message);
                    }


                });
            },
            handlePaths() {
                 dataService.axiosPost("updateinfo", {"rid":this.id,"pages":this.role.paths,"ctypes":this.role.ctypes})
            },
        },
        mounted() {
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.role = results;
                });
                dataService.axiosFetch("allcontenttypes").then(results => {
                    this.contenttypes = dataService.getCheckboxOptions(results);

                });
                dataService.axiosFetch("paths").then(results => {
                    this.paths = results;
                });
                dataService.axiosFetch("permissions").then(results => {
                    this.permissions = dataService.getCheckboxOptions(results);
                });
            }
        }
    };
</script>
<style>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .ant-checkbox-group-item
    {display:inline-block;width:100%; margin-bottom:10px;}
</style>
